import React from 'react';
import PropTypes from 'prop-types';
import { jsonLdScriptProps } from 'react-schemaorg';
import Head from 'next/head';
import { StoryblokCache, initializeRedis } from '@cache/redis';
import { OllieStoryblokClient } from '@web/cms/api';
import { withGladlySetting } from '../gladly';
import { withFlagshipSession } from '../auth/serverSide';
import { Bridge, CMSMeta, CMSStory, useCMSEditor, VALID_STORIES } from '../cms';
import { server, shared } from '../config';
import { PrefixedReporterProvider, useTrackPage } from '../reporter';

const CMSPage = ({ content, bridged, isHomePage }) => {
  const syncedContent = useCMSEditor(content);
  const taggingPrefix = syncedContent?.tagging_prefix ?? 'CMS Page';
  useTrackPage(taggingPrefix);

  return (
    <>
      <Head>
        {isHomePage && (
          <script
            {...jsonLdScriptProps({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: 'Ollie',
              url: 'https://www.myollie.com/',
              logo: '/ollie-logo.svg',
              description:
                'Ollie produces human-grade, clean dog food with real ingredients dogs love to eat, tailoring it to their individual nutritional needs and delivering it to homes.',
              contactPoint: {
                '@type': 'ContactPoint',
                contactType: 'Customer Care',
                telephone: '844-886-5543',
                email: 'caninecare@myollie.com',
                areaServed: 'US',
                availableLanguage: 'English',
              },
              sameAs: [
                'https://twitter.com/olliepets',
                'https://www.facebook.com/MyOllie',
                'https://www.instagram.com/myollie/',
                'https://www.pinterest.com/myolliedotcom/',
              ],
            })}
          />
        )}
        <script
          type="text/javascript"
          src={`${shared.AWS_WAF_CHALLENGE_INTEGRATION_URL}`}
          defer
        />
      </Head>
      {bridged && <Bridge />}
      <CMSMeta content={syncedContent} />
      <PrefixedReporterProvider prefix={taggingPrefix}>
        <CMSStory content={syncedContent} isHomePage={isHomePage} />
      </PrefixedReporterProvider>
    </>
  );
};

/**
 * @type {import('next').GetServerSideProps}
 */
export const getServerSideProps = withGladlySetting(
  withFlagshipSession(async ({ params, preview }) => {
    const requestPath = params.path;
    // If someone requests the HOME_SLUG directly, send them to `/`.
    if (requestPath?.[0] === OllieStoryblokClient.HOME_SLUG) {
      return {
        redirect: {
          destination: '/',
          permanent: true,
        },
      };
    }

    // `requestPath` is defined except on the root page
    const path = requestPath?.join('/') ?? OllieStoryblokClient.HOME_SLUG;
    const isHomePage = path === OllieStoryblokClient.HOME_SLUG;
    const redis = initializeRedis({
      host: server.REDIS_HOST,
      port: server.REDIS_PORT,
    });
    const storyblokRedis = new StoryblokCache(redis);
    const ollieStoryblokClient = new OllieStoryblokClient(storyblokRedis);
    try {
      const res = await ollieStoryblokClient.getStory(path);
      const { content } = res.data.story;
      // If someone is on an offer_redirect page,
      if (content.component === 'offer_redirect') {
        const targetUrl = content.target.cached_url || content.target.url;
        const redirectTarget =
          targetUrl === OllieStoryblokClient.HOME_SLUG
            ? '/'
            : '/' + targetUrl + '/';
        return {
          redirect: {
            destination: `${redirectTarget}?offer_code=${content.coupon_code}`,
            permanent: true,
          },
        };
      }

      if (!VALID_STORIES.includes(content.component)) {
        return {
          notFound: true,
        };
      }

      return {
        props: {
          content,
          bridged: Boolean(preview),
          isHomePage,
        },
      };
    } catch (e) {
      if (e.response?.status === 404) {
        return {
          notFound: true,
        };
      }

      throw e;
    }
  }),
);

export default CMSPage;

CMSPage.propTypes = {
  content: PropTypes.object,
  bridged: PropTypes.bool,
  isHomePage: PropTypes.bool,
};
